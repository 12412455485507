.connectButton{
    border-radius:  10px !important;
    border-style: solid;
    border-width: 0.5px;
    border-color: #BEB9B4;
    background-color: white;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1); /* Right shadow */
}

.navbar{
    height: 110px;
    line-height: 110px;
    text-transform: uppercase;
    padding-left: 40px !important;
    padding-right: 40px !important;
    width: 100% !important;
  }

.navbarHeadline{
    font-size: 32px !important;
    font-weight: bold;
    text-shadow: 0px 5px 2px rgba(0, 0, 0, 0.1); /* Right shadow */

}

.wui-text{
    color: red !important
}