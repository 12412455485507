

.redeemHeadline {
    height: 50px;
    display: flex;
    justify-content: flex-start; 
    align-items: center; /* Centers vertically */
    font-size: 16px;
}


