
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Montserrat:wght@400;700&family=Roboto:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: "PosteramaRegular";  
  src: local("PosteramaRegular"),
    url("./fonts/PosteramaRegular.ttf") format("truetype");
}
@font-face {
  font-family: "BerlinSans";  
  src: local("BRLNSR"),
    url("./fonts/BRLNSR.TTF") format("truetype");
}

@font-face {
  font-family: "OpenSansLight";   
  src: local("OpenSansLight"),
    url("./fonts/OpenSansLight.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansBold";   
  src: local("OpenSansBold"),
    url("./fonts/OpenSansBold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSansRegular";  
  src: local("OpenSansRegular"),
    url("./fonts/OpenSansRegular.ttf") format("truetype");
}

src/fonts/PTSans-Regular.ttf
.break-word {
  word-wrap: break-word; /* Older browsers */
  overflow-wrap: break-word; /* Standard: CSS3 and newer */
}

.mainContainer {
  display: flex;
  flex:1;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center;
  min-height: 95vh !important;
  width: 100% !important;
}



.middleBoxSmall{
  width: 100%;
  margin:0 !important;
  padding: 0 !important;
  max-width: 600px; /* Set the maximum width for the middle column */
  padding: 24px;
}

.middleBoxFull{
  display: flex;
  width: 100%;
  margin:0 !important;

  margin-right:30px !important;
  margin-left:30px !important;
  padding: 0 !important;
  max-width: 1150px; /* Set the maximum width for the middle column */
}

.mainBox{
  border: 1px solid !important;
  border-color: #EEEEEE  !important;
  border-radius:  16px !important;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 1) !important;
  margin: 0px !important;
  padding: 24px !important;
  width: 100% !important;
  z-index: 2 !important;
}

.upperSelectBox{
  background-color: #d9d9d9 ;
}

#pageHeadline {
  font-family: "BerlinSans";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 45px;
  height: 70px;
  color:#006ddc;
  padding-left: 0px;
  margin-bottom: 2vh;
}

.middleBoxHeader{
  justify-content: center;
  width: 100%;
  text-align: center;
}

.buffer{
  height: 150px;
}

.inputCustom{
  color: #000000 !important;
  height: 48px;
  width: 100% !important;
  border: 0px !important;
  border-style: solid !important;
  border-color: transparent !important;
  background-color: transparent !important;
  padding: 5px !important;
  font-size: 32px;
  font-weight: bold !important;;
  
}
.inputCustom:focus {
  outline:none !important;
  border: 0px !important;
  border-style: solid !important;
  border-color: transparent !important;
}


.textBalance{
  font-size: 1em !important;
}



.modal-content{
  border: 1px !important;
  border-style: solid !important;
  border-color: #eeeeee !important;
}

.modal-header{
  background-color: #ffffff;
  color: #232323;
  border-bottom: 1px solid #eeeeee !important;
}
.modal-header .close {
  color: black !important;
  opacity: 1; /* Ensure the color is fully opaque */
}

.modal-header .close:hover,
.modal-header .close:focus {
    color: black !important;
    opacity: 0.7 !important; /* Slightly reduced opacity on hover/focus for effect */
}

.modal-body{
  background-color: #ffffff;
  color: #232323;
  padding-top: 30px !important;
}
.modal-footer{
  background-color: #ffffff;
  color: #232323;
  padding-top: 30px !important;
  border-top: 0px solid #eeeeee !important;
  width: 100%;
}

.ps-menuitem-root.css-1t8x7v1:hover {
  background-color: #000000 !important;
  background-color: #424242;
  color: #000000;
}

.assetSelectList{
  background-color: white;
  border-radius: 8px;
  font-size: 0.9em !important;
  padding: 0.6vw;
  border: 1px solid #eeeeee !important;
  margin-bottom: 10px;
  
}
.assetSelectList:last{
  border-bottom: 0px;
  
}
.assetSelectList:hover{
  background-color: rgba(163, 210, 97, 0.1);
  border: #A3D261 1px solid !important;
}

.assetSelectListName{
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.assetSelectListBalance{
  font-weight: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.searchField{
  border: 1px solid #eeeeee !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  color: black;
  height: 52px;
  padding: 16px 20px 16px 20px;
}

.searchField:focus {
  outline: none;
}

.socials:hover {
  filter: invert(11%) sepia(70%) saturate(662%) hue-rotate(77deg)
    brightness(98%) contrast(100%);
    text-decoration: none !important;
}

.mainArea{
  width: 100% !important;
  margin: 0 !important;
  margin: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-style: solid;
  border-width: 0px;
  width: 100% !important;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}



.buttonText {
  font-size: 0.9vw !important;
}

.backgroundImage {
  position: relative;
  width: 100%;
  background-image: url(./img/graphics/honeycomb_orange.png);
  background-size: 100% auto; /* Set background size to cover full width and maintain aspect ratio */
  background-repeat: repeat-y; /* Repeat horizontally */
}

.backgroundImageNew {
  position: relative;
  width: 100%;
  background-size: 100% auto; /* Set background size to cover full width and maintain aspect ratio */
  background-repeat: repeat-y; /* Repeat horizontally */
}

.layer {
  display:flex;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  height: 100%;
}

.transparent{
  background-color: rgba(0, 0, 0, 0.4) !important;
}


.web3modal-modal-card{
  width: 400px!important;
  min-width: unset!important;
  max-width: unset!important;
}

.web3modal-provider-container{
  flex-direction: row!important;
}

.web3modal-provider-description{
  display:none;
}

.buyButton, .sellButton{
  height: 49px;
  line-height: 49px;
  width: 70px;
  text-align: center;
  border-radius:  10px;
  font-size: 16px;
  color: #086A24;
  margin-bottom: 4px;
  font-weight: bold;
}

.buyButton{
  border: 0 solid;
  border-radius:  10px;
  background-color: #A3D261;
  margin-right: 8px;
}

.sellButton{
  background-color: #ffffff;
  border: 1px solid #A3D261; /* Optional: adds a separator between the buttons */
}



.show>.dropdown-menu{
  display: block;
  position:absolute;
}

.bold{
  font-weight: bold;
}


body { 
	font-family: 'PT Sans', sans-serif;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background-color: white;
}




.nav-link:hover{
  color:#1afba9 !important;
}

.nav-link-active{
  color: #1afba9 !important;
}

.link {
  color: #1afba9;
  text-decoration: none;
  background-color: transparent;
}
.link:hover {
  color: #1afba9;
  text-decoration: underline;
}


.border-3 {
    border-width:3px !important;
}

.mainModal { 
	background: #171A21 !important; 
	width: 90%;
}

.mainContent {
  position:absolute;
  top:0%;
  left:0%;
  right:0%;
}

.underlined-fuchsia {
  border-bottom-style: solid;
  border-color: #FF00FF;
}

.tooltip-inner {
  background-color: #666666 !important;
  color: #666666 ;
}


.bottom-right {
  position: absolute;
  right: 3%;
  bottom:3%;
  
}





.selectAssetItem:hover {
	background-color: #1afba9;
}
.selectAssetItem {
  background-color: black;
	outline: 1px solid #666666;
}

.addressGovernance{
	font-size: 85%;
}

.smaller{
	font-size: 85%;
}
.vertical_center {
    display: flex;
    align-items: center;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#selectMain {
  border-style: solid;
  border-color: #ffffff;  
}

#selectMain:hover {
  border-style: solid;
  border-color: #1afba9;

}

#greyModal {
  background-color: #939393 !important;  
}




input {
  float: right !important;
}
input::placeholder {
  color: black;
}
input[type=text] {
  border: 0px;
  border-radius: 4px;
  background-color: #f8f9fa;
  float: right !important;
}

input[type=number] {
  border: 0px;
  border-radius: 4px;
  background-color: #f8f9fa;
  float: right !important;
}

input[type=decimals] {
  border: 0px !important;
  border-radius: 4px;
  background-color: #f8f9fa;
  float: right !important;
}



#mainBox{
	border: 0px solid !important;
  border-color: #96caff  !important;
  border-radius:  20px !important;
  background: rgba(255, 255, 255, 1) !important;
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
  z-index: 2;
}


#mainBoxInner{
  border: 0px solid !important;
  border-color: #1afba9  !important;
  border-radius:  20px !important;
}

#mainBoxClicked{
  border: 2px solid !important;
  border-color: #1afba9  !important;
  border-radius:  20px !important;
}

#mainBoxUpLeft{
  border: 0px solid !important;
  border-color: #96caff  !important;
  border-top-left-radius: 20px !important;
}
#mainBoxUpRight{
  border: 0px  !important;
  border-color: #96caff  !important;
  border-top-right-radius: 20px !important;
}
#mainBoxDownLeft{
  border: 0px  !important;
  border-color: #96caff  !important;
  border-bottom-left-radius: 20px !important;
}
#mainBoxDownRight{
  border: 0px  !important;
  border-color: #96caff  !important;
  border-bottom-right-radius: 20px !important;
}
#mainBoxUp{
  border: 0px  !important;
  border-color: #96caff  !important;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}
#mainBoxDown{
  border: 0px  !important;
  border-color: #96caff  !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

#innerBox{
  border: 0px  !important;
  border-style: solid !important;
  border-color: #1afba9  !important;
  border-radius:  16px !important;
}

#innerBoxUpLeft{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-left-radius: 16px !important;
}
#innnerBoxUpRight{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-right-radius: 16px !important;
}
#innerBoxDownLeft{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-left-radius: 16px !important;
}
#innerBoxDownRight{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-right-radius: 16px !important;
}
#innerBoxUp{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-top-right-radius: 16px !important;
  border-top-left-radius: 16px !important;
}
#innerBoxDown{
  border: 0px  !important;
  border-color: #1afba9  !important;
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
}

#governanceBox{
  border: 0px solid !important;
  border-radius:  20px !important;
  background-color: #2d2d2d !important;
  color:  white !important;
}

#governanceBoxClicked{
  border: 0px solid !important;
  border-radius:  20px !important;
  background-color: #2d2d2d  !important;
  color:  #1afba9 !important;
}

#tradeBoxText{
  font-size: 1.5vw;
  font-weight: bold;
}

.subBox{
  border: 1px  !important;
  border-style: solid !important;
  border-color: #EEEEEE  !important;
  border-radius:  8px !important;
  padding: 20px !important;
}

.subBoxLine {
  display: flex;
  min-height: 41px;
}
.cyber-h {
  position: relative;
  animation-name: h1glitched;
  animation-duration: calc(var(--glitched-duration)* 1.4);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}


#buttonRounded{
  border: 1px  !important;
  border-color: #1afba9  !important;
  border-radius:  15px !important;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1em !important;
  
}

#submit{
  border: 1px  !important;
  border-color: #1afba9  !important;
  border-radius:  20px !important;;
}

.issuaaButton{
  height: 48px;
  line-height: 20px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  border: 0px  !important;
  padding-top: 14px;
  padding-bottom: 14px;
  border-style: solid !important;
  border-color: #d9d9d9  !important;
  border-radius:  10px !important;
  background-color: #086A24;
  color: #ffffff;
  margin-top: 10px;
  font-size: 16px !important;
}

.issuaaButton:hover{
  background-color: rgba(8, 106, 36, 0.8);
}

.transactionButton{
  height: 48px;
  line-height: 20px;
  min-width: 200px;
  width: 100%;
  display: flex;
  justify-content: center; /* Centers horizontally */
  border: 0px  !important;
  padding: 14px;
  border-style: solid !important;
  border-color: #d9d9d9  !important;
  border-radius:  10px !important;
  background-color: #086A24;
  color: #ffffff;
  margin-top: 10px;
  font-size: 16px !important;
}

.transactionButtonDisabled{
  height: 48px;
  line-height: 20px;
  min-width: 200px;
  width: 100%;
  display: flex;
  justify-content: center; /* Centers horizontally */
  flex-direction: row;
  border: 1px  !important;
  padding: 14px;
  border-style: solid !important;
  border-color: #d9d9d9  !important;
  border-radius:  10px !important;
  background-color: rgba(190, 185, 180, 0.2);
  margin-top: 10px;
  font-size: 16px !important;
}

.transactionButton:hover{
  background-color: rgba(8, 106, 36, 0.8);
}


.selectAssetButton{
  display: flex;
  justify-content: flex-end;
  min-height: 48px;
  line-height: 20px;
  min-width: 200px;
  border: 1px  ;
  border-style: solid;
  border-color: transparent;
  padding-left: 20px !important;
  padding-right: 20px;
  padding-bottom: 14px;
  padding-top: 14px;
  border-style: solid !important;
  border-radius:  10px !important;
  background-color: rgba(163, 210, 97, 0.1);
  font-size: 16px !important;
  text-align: center;
}

.selectAssetButton:hover{
  border-color: #A3D261 !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.selectAmountRow {
  display: flex;
  justify-content: flex-end;
}
.percentBox {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  border: 1px #EEEEEE solid;
  border-radius: 10px;
  height: 36px;
  line-height: 20px;
  width: 63px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bold;
  color: #086A24;
}
.selectSettingsRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}
.tradingBoxRow {
  display: flex;
  min-height: 68px;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.balanceRow {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.issuaaButtonDeactivated{
  height: 48px;
  line-height: 20px;
  min-width: 200px;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  border: 0px  !important;
  padding: 14px;
  border-style: solid !important;
  border-color: #d9d9d9  !important;
  border-radius:  10px !important;
  background-color: rgba(190, 185, 180, 0.2);
  margin-top: 10px;
  font-size: 16px !important;
}

.selectButtonRow {
  display: flex;
  justify-content: space-between;
  background-color: rgba(163, 210, 97, 0.2);
  padding: 8px;
  border-radius: 8px;
}

.selectButtonSelected{
  background-color: #A3D261;
  color: #086A24;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 57px;
  line-height: 15px;
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
}
.selectButtonUnselected{
  color: #086A24;
  border-radius: 8px;
  font-weight: bold;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  height: 57px;
  line-height: 15px;
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
}


.assetBoxIcon {
  margin: 0 10px 0 0;
}

.stablecoinAssetBox{
  display: flex;
  margin-left: auto;
  height: 61px;
  line-height: 41px;
  border-radius: 10px;
  background-color:  rgba(190, 185, 180, 0.1);
  padding: 10px 20px 10px 20px;
}
.tradingBoxMiddleRow {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  padding-top: 10px;
  padding-bottom: 10px;
}

.roundedCorners  {
  border-radius: 20px;

}

table  {
  border-collapse: collapse;
  overflow: hidden;
  font-size: 0.9em;

}





th {
  padding: 1em;
  border-bottom: 1px solid #006ddc !important; 
  border-top: 0px solid #006ddc !important;
  font-weight: bold !important;
}

tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

tr:last-child {
  padding: 1em;
  border-bottom: 0px solid #006ddc !important; 
}


#largeIPT{
  text-shadow: 0 0 3px black;
}

.loadingBlockchainModal {
  margin: 0px !important;
  border-radius:  0px !important;
  background-color: #086A24 !important;
  color: white;

}

.loadingBlockchainModalOuter{
  background-color: transparent !important;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    height: 100%;
    font-family: 'PT Sans', sans-serif !important;
}

.messageBox {
  position: fixed;
  bottom: 20px;
  right: 40px;
  background: #FFFFFF;
  width: 300px;
  padding: 20px;
  text-align: center;
  word-break: break-word;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 4px 8px 0px #00000014;

}

.messageBoxHeader {
  height: 65px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.messageBoxBody {
  text-align: left;
  font-size: 16px;
  
}
.messageBoxFooter {
  text-align: left;
  color: #086A24;
  padding-top: 20px;
}
.messageBoxFooter a {
  color: #086A24;
}

.messageBoxFooter a:hover {
  
  color: #086A24; /* Ensures the color stays the same on hover */
}




.spinner-border-slow {
  animation-duration: 1s !important;
  line-height: 100%;
  height: 20px;
  margin-right: 10px;

}

.settingsRow {
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.settingsInputArea {
  background-color: #FFFFFF;
  border: 1px #eeeeee solid;
  height: 52px; /* Correct the height value */
  padding: 16px 20px;
  gap: 0px;
  border-radius: 8px;
  display: flex; /* Add this line to enable flexbox */
  flex-direction: row; /* Ensure children are in a row */
  justify-content: space-between; /* Adjust spacing if needed */
  align-items: center; /* Align items vertically */
  opacity: 1; /* Set to 1 to make visible */
}
.settingsInputArea input{
  background-color: #ffffff;
}
.settingsInputArea input:focus{
  border:none !important;
  outline: none;
}

.settingsInputArea input {
  flex: 1; /* Allow the input to take available space */
}