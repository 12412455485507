



list-assets{
    background-color: black !important;
}

.factoryHeadline {
    line-height: 20px;
    margin-bottom: 16px;
    font-size: 16px;
}


.factorySelectAssetButton{
    display: flex;
    justify-content: space-between;
    
  }

  .factorySelectButtonText {
    display: flex;
    flex-direction: row;
  }
  .factoryIconRight {
    display: flex;
    justify-content: right;
  }

  .factoryModalHeader {
    font-size: 20px;
    font-weight: bold !important;
    padding-bottom: 20px;
  }