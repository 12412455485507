.footer {
    width: 100%;
    display: flex;
    justify-content: space-between; /* Space between for left and right alignment */
    align-items: center; /* Center align items vertically */
    padding: 20px 40px 20px 40px;    
}


.footer .footerSocials, .footer .footerCopyright {
    flex: 1;
  }

.footer .footerCopyright {
    text-align: right; /* Align text to the right */
    margin-left: auto; /* Pushes the right div to the end (right) */
}
.footer .footerSocials {
    position: relative;
    text-align: left; /* Align text to the right */
    left: 0;
    
}
.footerCopyright {
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    color: #BEB9B4;
  }

  .footerSocials{
}
  