.mainpageContent{
    font-size: 1.2vw !important;
}

.mainpageDashboard {
    border-style: solid;
    border-width: 1px;
    border-color: #EEEEEE;
    border-radius: 16px;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.1); /* Right shadow */
    padding: 20px;
}


.contentContainer{
    padding-bottom: 40px !important;
    display: flex;
    flex-direction: column;
    min-height: 1vh;
    width: 100% !important;
  }

.infoRow{
    display: flex;
    justify-content: space-between;
    min-height: 120px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.mainpageHeadline {
    font-size: 20px;
    font-weight: bold;
}

.infoBox {
    color: #232323 !important;
    background-color: rgba(126, 176, 85, 0.05);
    border: 0.5px solid #7EB055; /* Combined border property */
    height: 162px;
    width: calc(min(24%,267px)) ;
    border-radius:  10px !important;
    padding: 20px;  
}

.infoBoxIcon {
    line-height: 41px;
    font-size: 30px;
    color: #086A24;
}
.infoBoxItem{
    line-height: 41px;
    font-size: calc(min(2.3vw,32px)) !important;
    font-weight: bold;
    color: #232323;
    margin-top: 10px;
}

.infoBoxItem2{
    line-height: 21px;
    font-size: calc(min(1.3vw,16px)) !important;
    color: #BEB9B4;
    font-weight: bold;
}

.mainTable{
    background-color: transparent !important;
    text-align: left;
}

.mainTable thead{
    color: #BEB9B4 !important;
    font-weight: bold;
    font-size: 14px;
    text-align: left !important;
}

.mainTable table  {
    border-collapse: collapse;
    overflow: hidden;
    font-size: 16px;
}
  
.mainTable td {
    border-top: 0.5px solid #EEEEEE !important;
    border-bottom: 0.8px solid #EEEEEE !important; 
    font-weight: normal;
    height: 70px;
    padding: 10px
}



.mainTable th {
    border-bottom: 0.8px solid #EEEEEE !important; 
    border-top: 0.5px solid #EEEEEE !important;
    height: 70px;
    padding: 10px
}

.mainTable tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.mainTable tr:last-child {
border-bottom: 0px solid #EEEEEE !important; 
}

.mainTable td:last-child {
    display: flex;
    justify-content: flex-end;
}


.firstRow {
    border-right: 1px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: 0px;
    border-style: solid;
    border-color: white;
    padding-right: 10px;
}