.subBoxTWIND {
    padding: 0px !important;
    margin-bottom: 20px;
}

.TWINDFirstRowGreen {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background-color: rgba(126, 176, 85, .05);
    color: #086A24;
    font-weight: bold;
}
.TWINDFirstRow {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    
}
.TWINDMiddleRow {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}
.TWINDLastRow {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background-color: rgba(126, 176, 85, .05);
    color: #086A24;
}
.TWINDLastRowWhite {
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}

.dots {
    border-color: rgba(126, 176, 85, .5);
    border-style: dotted;
    border-width: 0px 0px 1px 0px;
    width: 50%;
    height: 10px;
}

.boldGreen {
    font-weight: bold;
    color: #086A24;
}

.lockButton{
    width: 200px;
    display: flex;
    justify-content: flex-end;
    min-height: 48px;
    line-height: 20px;
    border: 0px  !important;
    padding-left: 20px !important;
    padding-right: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    border-style: solid !important;
    border-radius:  10px !important;
    background-color: rgba(8, 106, 36, 1);
    color: white;
    font-weight: bold;
    font-size: 16px !important;
    text-align: center;
  }

  .lockButton:hover{
    background-color: rgba(8, 106, 36, 0.8);
  }

  .TWINDButtonRow {
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;
  }
  .TWINDButtonRowLast {
    display: flex;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }