


.sidebar {
  display: flex;
  flex-direction: column;
  width: 243px;
  min-height: 1vh;
  background-color: white;
  border-style: solid;
  border-color: #eeeeee;
  border-width: 0.5px;
  box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1); /* Right shadow */
}

.sidebarLogo {
  padding: 40px;
  border-style: solid;
  border-color: #EEEEEE;
  border-width: 0px 0px 0.5px ;
}

.sideBarMenu{
  border-style: solid;
  border-color: #EEEEEE;
  border-width: 0px 0px 0.5px ;
}

.sideBarMenu a:hover {
  text-decoration: none;
}
.menuItem {
  height: 41px;
  line-height: 41px; /* Vertically align text */
  margin: 10px 20px 0px 20px;
  border-radius: 8px;
  
  background-color: #ffffff;
  color: #232323;
  font-size: 16px;
  font-weight: bold;
  padding-left: 16px;
  padding-right: 16px;
}
.menuItem:hover {
  background-color: rgba(126, 176, 85, 0.05);
  color: #086A24;
  text-decoration: none !important;
}
.menuItem a {
  color: #232323
}
.menuItem a:hover {
  text-decoration: none !important;
}

  
.menuButtonGreen{
  height: 49px;
  line-height: 49px;
  text-align: center;
  border-radius:  10px !important;
  font-size: 16px !important;
  background-color: #086A24 !important;
  color: white !important;
}

.menuButtonTWIN {
  color: #086A24 !important;
  font-weight: bold;
  background-color: rgba(126, 176, 85, 0.05);
  border: 0.5px solid #7EB055; /* Combined border property */
  border-radius:  10px !important;
  padding-left: 16px;
  padding-right: 16px;
}
.menuButtonTWIN1 {
  color: #A3D261;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
}

.menuButtonTWIN2 {
  color: #086A24;
  font-size: 24px;
  font-weight: bold !important;
  padding-top: 8px;
  padding-bottom: 10px;
}

.menuButtonTWIND {
  color: #086A24 !important;
  font-weight: bold;
  background-color: rgba(126, 176, 85, 0.05);
  border: 0.5px solid #7EB055; /* Combined border property */
  border-radius:  10px !important;
  padding: 16px;
}
.sidebarPanda {
  margin-top: auto;
  padding-left: 30px;
  margin-top: 60px;
}
.sideBarMenuLow {
  
}