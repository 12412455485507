.mainPortfolioContent {
    font-size: calc(min(1.4vw,20px)) !important;
}

.portfolioTotalValueBox{
    color: #232323 !important;
    background-color: rgba(126, 176, 85, 0.05);
    border: 0.5px solid #7EB055; /* Combined border property */
    height: 162px;
    width: calc(min(28%,267px)) ;
    border-radius:  10px !important;
    padding: 20px;  
}

.portfolioAssetBox {
    margin-top: 40px;
    border: 1px #EEEEEE solid;
    border-radius: 16px;
}

.portfolioBoxHeader {
    color: #232323;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 40px;
}

.mainPortfolioContent{
    font-size: calc(min(1.4vw,16px)) !important;
    background-color: transparent !important;
    text-align: left;
}

.mainPortfolioContent thead{
    color: #BEB9B4 !important;
    font-weight: bold;
    font-size: 14px;
    text-align: left !important;
}

.mainPortfolioContent table  {
    border-collapse: collapse;
    overflow: hidden;
    font-size: 16px;
}
  
.mainPortfolioContent tr {
    border-top: 0.5px solid #EEEEEE !important;
    border-bottom: 0.8px solid #EEEEEE !important; 
    font-weight: normal;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 70px;
}
.mainPortfolioContent td:first-child, .mainPortfolioContent th:first-child {
    font-weight: normal;
    height: 64px;
    padding-left: 40px;
}

.mainPortfolioContent td:last-child {
    display: flex;
    justify-content: flex-end;
}

.mainPortfolioContent th {
    color: #BEB9B4 !important;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 0.8px solid #EEEEEE !important; 
    border-top: 0.5px solid #EEEEEE !important;

}
.mainPortfolioContent td {
    height: 70px;
    padding: 10px;
    font-size: 16px;
}

.mainPortfolioContent tr:last-child {
    border-bottom: 0px solid #EEEEEE !important; 
    background-color: rgba(126, 176, 85, 0.05) !important;
}

.portfolioStatusLive{
    background-color: rgba(126, 176, 85, 0.05);
    border-radius: 20px;
    color: #086A24;
    font-weight: lighter;
    text-align: center;
    line-height: 30px;
}