.governanceRow{
    display: flex;
    flex-wrap: wrap;
    min-height: 120px;
    padding: 0 !important;
    
  }

  .governanceIntroText{
    font-size: calc(max(1.35vw,15px)) !important;
    padding-bottom: 30px;;
  }

  .governanceBox{
    border-radius:  10px !important;
    text-align: left;
    min-height: 210px !important;
    margin: 0;
    padding: 20px !important;
    width: 22%;
    font-size: 20px;
  }
  .governanceBox:hover{
    background-color: rgba(126, 176, 85, 1);
  }
  .governanceBox20{
      background-color: rgba(126, 176, 85, 0.2);
  }
  .governanceBox40{
    background-color: rgba(126, 176, 85, 0.4);
  }  
  .governanceBox60{
    background-color: rgba(126, 176, 85, 0.6);
  }
  .governanceBox80{
    background-color: rgba(126, 176, 85, 0.8);
  }  


.governanceBoxItem{
    font-size: calc(min(2.3vw,30px)) !important;
    margin-top: 10px;
}

.governanceBoxItem2{
    font-size: calc(min(1.8vw,20px)) !important;
    text-transform: uppercase;
}

.governanceOutput {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
}

.governanceAssetBox{
  border: 1px  !important;
  border-style: solid !important;
  border-color: #EEEEEE  !important;
  border-radius:  8px !important;
  padding: 20px !important;
  
    min-width: 400px;
    width: 100%;
    margin-bottom: 30px;
  }

.governanceButtonLightGreen{
  color: #086A24 !important;
  font-weight: bold;
  text-align: center;
  background-color: rgba(126, 176, 85, 0.20);
  border: 0.5px solid #7EB055; /* Combined border property */
  border-radius:  10px !important;
  padding: 10px;
  }
  
  .governanceButtonLightGreen:hover{
    background-color: rgba(126, 176, 85, 0.6);
  }

  .governanceButtonLightBlue{
    border: 3px  !important;
    padding: 10px !important;
    border-style: solid !important;
    border-color: #96caff  !important;
    border-radius:  15px !important;
    color: black !important;
    background-color: transparent !important;
    margin-top: 10px;
    font-size: 20px !important;
  }
  
  .governanceButtonLightBlue:hover{
    background-color: #96caff  !important;
    color: black !important;
  }

  .governanceButtonOrange{
    border: 3px  !important;
    padding: 10px !important;
    border-style: solid !important;
    border-color: #ffad12  !important;
    border-radius:  15px !important;
    color: black !important;
    background-color: transparent !important;
    margin-top: 10px;
    font-size: 20px !important;
  }
  
  .governanceButtonOrange:hover{
    background-color: #ffad12  !important;
    color: white !important;
  }

  .governanceButtonBlack{
    border: 3px  !important;
    padding: 10px !important;
    border-style: solid !important;
    border-color: #000000  !important;
    border-radius:  15px !important;
    color: black !important;
    background-color: transparent !important;
    margin-top: 10px;
    font-size: 20px !important;
  }
  
  .governanceButtonBlack:hover{
    background-color: #000000  !important;
    color: white !important;
  }