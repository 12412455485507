

.governanceTokenFirstRow {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-top: 20px;
}
.governanceTokenMiddleRow {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
}
.governanceTokenLastRow {
    height: 60px;
    line-height: 20px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    background-color: rgba(126, 176, 85, .05);
    color: #086A24;
}

.subBoxGovernanceToken {
    padding: 0px !important;
    margin-bottom: 20px;
}
.dots {
    border-color: rgba(126, 176, 85, .5);
    border-style: dotted;
    border-width: 0px 0px 1px 0px;
    height: 10px;
}

.boldGreen {
    font-weight: bold;
    color: #086A24;
}

.lockButton{
    min-width: 260px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: 48px;
    line-height: 20px;
    border: 0px  !important;
    padding-left: 20px !important;
    padding-right: 20px;
    padding-bottom: 14px;
    padding-top: 14px;
    border-style: solid !important;
    border-radius:  10px !important;
    background-color: rgba(8, 106, 36, 1);
    color: white;
    font-weight: bold;
    font-size: 16px !important;
    text-align: center;
  }

  .lockButton:hover{
    background-color: rgba(8, 106, 36, 0.8);
  }

  .buttonRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-size: 16px;
    padding: 20px;
  }

  .governanceTokenLockAmountRow{
    margin-bottom: 20px;
  }
  .governanceTokenDateAmountRow{
    margin-top: 20px;
  }
  .inputDate{
    width: 100%;
    padding: 14px;
    height: 48px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid #eeeeee  !important;
  }
  .inputAmount {
    width: 100%;
    padding: 14px;
    height: 48px;
    line-height: 20px;
    border-radius: 10px;
    border: 1px solid #eeeeee !important
  }
  .inputAmount:focus {
    outline: none;
  }
  .inputDate :focus {
    outline: none !important;
  }

  .react-datepicker__input-container:focus-visible {
    outline: none !important;
  }