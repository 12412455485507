

.form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
} 


.tradeBalance:hover {
	text-decoration: underline;
}
.textBalance {
    font-size: 85%;
    color:  #000000;
    text-align: right;
  
  }

